import React from 'react';
import { Game } from '@src/pages';
function app() {
    return (
        <div className="App">
            <Game />
        </div>
    );
}

export default app;
